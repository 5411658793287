// React
import { FunctionComponent } from "react";
// Styles
import styles from "./Error.module.css";
// Fhir Front Library
import { Title } from "@fyrstain/fhir-front-library";
// Components
import JanusPage from "../../components/JanusPage/JanusPage";
// Translation
import i18n from "i18next";

const Error: FunctionComponent = () => {
    
  //////////////////////////////
  //          Content         //
  //////////////////////////////

  return (
    <JanusPage loading={false} fitFooter={true} needsLogin={false}>
      <div className={styles.mainErrorContainer}>
        <img
          className={styles.imageIcon}
          src="../assets/oops.png"
          alt="Oops !"
        />
        <Title
          level={1}
          prefix={i18n.t("title.error.oops")}
          content={i18n.t("title.error.problem")}
        />
      </div>
    </JanusPage>
  );
};

export default Error;
