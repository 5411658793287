// React
import { FunctionComponent } from "react";
// Components
import TooltipCustom from "../TooltipWrapper/TooltipWrapper";
// React BootStrap
import { Spinner } from "react-bootstrap";
// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Translation
import i18n from "i18next";

////////////////////////////////
//           Props            //
////////////////////////////////

interface LoadingButtonProps {
  onClick: () => void;
  isLoading: boolean;
  icon: any;
  isDisabled?: boolean;
  disabledTooltipText?: string;
  enabledTooltipText?: string;
  operationName?: string;
}

const LoadingButton: FunctionComponent<LoadingButtonProps> = ({
  onClick,
  isLoading,
  icon,
  isDisabled,
  disabledTooltipText,
  enabledTooltipText,
  operationName, 
}) => {
    
  /////////////////////////////////////
  //      Constants / ValueSet       //
  /////////////////////////////////////

  // A tooltip with text for an enabled button using an operation ($populate / $validate etc)
  const tooltipTextWithOperation = operationName
    ? `${enabledTooltipText} ${operationName}`
    : enabledTooltipText;

  //////////////////////////////
  //          Content         //
  //////////////////////////////

  return (
    <div onClick={!isDisabled ? onClick : undefined}>
      {isLoading ? (
        // To show the spinner when isLoading is true
        <Spinner
          animation="border"
          role="status"
          className="spinnerPlayLoading"
        >
          <span className="visually-hidden">{i18n.t("text.loading")}</span>
        </Spinner>
      ) : (
        // To see a tooltip dynamic text if the button is disabled or enabled
        <TooltipCustom
          id="tooltipLoadingButton"
          text={isDisabled ? disabledTooltipText : tooltipTextWithOperation}
        >
          <FontAwesomeIcon
            icon={icon}
            size="2x"
            className={isDisabled ? "buttonIconDisabled" : "actionIcon"}
          />
        </TooltipCustom>
      )}
    </div>
  );
};

export default LoadingButton;
