// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ToastQueueProvider_toastOpacity__JNMSc {
  background-color: white;
  opacity: 1 ;
}
`, "",{"version":3,"sources":["webpack://./src/components/ToastQueueProvider/ToastQueueProvider.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,WAAW;AACb","sourcesContent":[".toastOpacity {\r\n  background-color: white;\r\n  opacity: 1 ;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toastOpacity": `ToastQueueProvider_toastOpacity__JNMSc`
};
export default ___CSS_LOADER_EXPORT___;
