// React
import { FunctionComponent } from "react";
// Components
import JanusPage from "../../components/JanusPage/JanusPage";
// Styles
import styles from "./InProgress.module.css";
// Fhir front library
import { Title } from "@fyrstain/fhir-front-library";
// Translation
import i18n from "i18next";

const InProgress: FunctionComponent = () => {

  //////////////////////////////
  //          Content         //
  //////////////////////////////
  
  return (
    <JanusPage loading={false} fitFooter={true} needsLogin={false}>
      <div className={styles.mainErrorContainer}>
        <img
          className={styles.imageIcon}
          src="/assets/InProgress.png"
          alt="Oops !"
        />
        <Title
          level={1}
          prefix={i18n.t("title.inprogress.wip")}
          content={i18n.t("title.inprogress.comingsoon")}
        />
      </div>
    </JanusPage>
  );
};

export default InProgress;