// React
import { FunctionComponent, useCallback, useEffect, useState } from "react";
// Components
import JanusTestablePage from "../../components/JanusTestablePage/JanusTestablePage";
import CodeEditor from "../../components/CodeEditor/CodeEditor";
import Output from "../../components/CodeEditor/Output";
import LoadingButton from "../../components/LoadingButton/LoadingButton";
import { ToastQueueProvider } from "../../components/ToastQueueProvider/ToastQueueProvider";
import TooltipCustom from "../../components/TooltipWrapper/TooltipWrapper";
// Fhir Front library
import { FhirStatus, StatusTag, Title, QuestionnaireDisplay, ValueSetLoader } from "@fyrstain/fhir-front-library";
// FHIR
import Client from "fhir-kit-client";
// Resources
import {
  Questionnaire,
  Parameters,
  QuestionnaireResponse,
  Bundle,
} from "fhir/r5";
// Translation
import i18n from "i18next";
// React Bootstrap
import { Button, Card, CardGroup, Form } from "react-bootstrap";
// Navigation
import { useNavigate, useParams } from "react-router-dom";
// Styles
import styles from "./QuestionnaireDetails.module.css";
// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faRotateRight,
  faPlay,
  faGear,
  faEye,
  faEyeSlash,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";

////////////////////////////////
//         Interfaces         //
////////////////////////////////

interface inputs {
  name: string;
  type: string;
  mode?: string;
  content?: string;
  profile?: string;
}

const QuestionnaireDetail: FunctionComponent = () => {

  /////////////////////////////////////
  //      Constants / ValueSet       //
  /////////////////////////////////////

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Questionnaire informations
  const { questionnaireId } = useParams();
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");
  const [responseFormat, setResponseFormat] = useState("JSON");

  // Contents & Resources
  const [questionnaireContent, setQuestionnaireContent] = useState("");
  const [questionnaireResource, setQuestionnaireResource] = useState(
    {} as Questionnaire
  );
  const [initialQuestionnaireContent, setInitialQuestionnaireContent] =
    useState("");
  const [questionnaireResponseContent, setQuestionnaireResponseContent] =
    useState("");
  const [outputContent, setOutputContent] = useState(new Array<inputs>());

  // To enable or disabled the "play" button to que QuestionnaireResponse field when the user has used the populate operation
  const [isPopulated, setIsPopulated] = useState(true);

  // Show the spinner when "true" and hide when "false"
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isExtractLoading, setIsExtractLoading] = useState(false);

  // To disable the "check" button to the Output field when the user has an error
  const [isFailingOperation, setIsfailingOperation] = useState(false);

  // To show or hide the content/card with the buttonToggle
  const [showQuestionnaire, setShowQuestionnaire] = useState(true);
  const [showQuestionnaireResponse, setShowQuestionnaireResponse] =
    useState(true);
  const [showOutput, setShowOutput] = useState(true);

  // Creating a Toast using the Component Toast
  const { createToast } = ToastQueueProvider.useToastQueue();

  /////////////////////////////////////
  //             Client              //
  /////////////////////////////////////

  const fhirClient = new Client({
    baseUrl: process.env.REACT_APP_FHIR_URL ?? "fhir",
  });

  const fhirOperationClient = new Client({
    baseUrl: process.env.REACT_APP_QUESTIONNAIRE_URL ?? "fhir",
  });

  //////////////////////////////
  //           Error          //
  //////////////////////////////

  const onError = useCallback(() => {
    navigate("/Error");
  }, [navigate]);

  ////////////////////////////////
  //           Actions          //
  ////////////////////////////////

  useEffect(() => {
    load();
  }, []);

  /**
   * To load the Questionnaire and switch the state of setLoading
   */
  async function load() {
    setLoading(true);
    await loadQuestionnaire();
    setLoading(false);
  }

  /**
   * Load Questionnaire from the back to populate the fields.
   *
   * @returns the promise of a Questionnaire.
   */
  async function loadQuestionnaire() {
    try {
      const response = await fhirClient.read({
        resourceType: "Questionnaire",
        id: questionnaireId ?? "",
      });
      if (response.resourceType !== "Questionnaire") {
        throw Error(response.statusText);
      }
      const questionnaire: Questionnaire = response as Questionnaire;
      const stringifiedQuestionnaire = JSON.stringify(questionnaire, null, "\t");
      setName(questionnaire.title ? questionnaire.title : "N/A");
      setDescription(
        questionnaire.description ? questionnaire.description : "N/A"
      );
      setStatus(questionnaire.status ? questionnaire.status : "N/A");
      setQuestionnaireContent(stringifiedQuestionnaire);
      setQuestionnaireResource(questionnaire);
      setInitialQuestionnaireContent(stringifiedQuestionnaire);
    } catch (error) {
      onError();
    }
  }

  //Functions to show or hide the cards with a ToggleButton
  function toggleQuestionnaire() {
    setShowQuestionnaire(!showQuestionnaire);
  }
  function toggleQuestionnaireResponse() {
    setShowQuestionnaireResponse(!showQuestionnaireResponse);
  }
  function toggleOutput() {
    setShowOutput(!showOutput);
  }

  /**
   * Function to stringify the response of the questionnaire in JSON
   * @param response Response of the questionnaire
   * @returns a stringify response
   */
  function parseResponse(response: QuestionnaireResponse) {
    setQuestionnaireResponseContent(JSON.stringify(response, null, "\t"));
  }

  /**
   * Function to stringify the response of the extract in JSON
   * @param response Response of the extract
   * @returns a stringify response
   */
  function parseExtractResponse(response: Bundle) {
    let inputs = new Array<inputs>();
    response.entry?.map((entry) => {
      inputs.push({
        name: entry.resource?.id ? entry.resource?.id : "",
        type: entry.resource?.resourceType ? entry.resource?.resourceType : "",
        content: JSON.stringify(entry.resource, null, "\t"),
      });
    });
    // Update the state with the formated data
    setOutputContent(inputs);
  }

  function getQuestionnaireAsResource(): Questionnaire {
    return JSON.parse(questionnaireContent) as Questionnaire;
  }

  function getQuestionnaireResponseAsResource(): QuestionnaireResponse {
    return JSON.parse(questionnaireResponseContent) as QuestionnaireResponse;
  }

  /**
   * Save the content of the questionnaire
   */
  async function onSave() {
    try {
      await fhirClient.update({
        resourceType: "Questionnaire",
        id: questionnaireId ?? "",
        body: JSON.parse(questionnaireContent),
      });
    } catch (error) {
      onError();
    }
  }

  /**
   * Reset the Questionnaire's content, returning the initial content 
   */
  const resetQuestionnaireContent = () => {
    setQuestionnaireContent(initialQuestionnaireContent);
  };

  /**
   * Function to use the operation Populate for a questionnaire
   */
  async function onPopulate() {
    setIsDataLoading(true);
    // Use the parameter questionnaire
    let parameter: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "questionnaire",
          resource: getQuestionnaireAsResource(),
        },
      ],
    };
    await fhirOperationClient
      .operation({
        name: "populate",
        input: parameter,
        resourceType: "Questionnaire",
      })
      .then((response) => {
        parseResponse(response);
        setIsPopulated(false);
        setIsfailingOperation(false);
      })
      .catch((error) => {
        setIsDataLoading(false);
        setIsfailingOperation(true);
        // See errors in the code editor (Output)
        setOutputContent([
          {
            name: "error",
            type: "Error",
            content: error.response?.data
              ? JSON.stringify(error.response?.data, null, "\t")
              : error.toString(),
          },
        ]);
        // A toast when an error occured
        createToast({
          title: i18n.t("text.errorvalidateheader"),
          icon: faTriangleExclamation,
          body: error.response
            ? JSON.stringify(
                error.response.data.issue[0].diagnostics,
                null,
                "\t"
              )
            : i18n.t("text.errorvalidatetext"),
        });
      });
    setIsDataLoading(false);
  }

  /**
   * Function using the operation Extract to extract the data from a questionnaire response
   */
  async function onExtract() {
    setIsExtractLoading(true);
    // Use the parameter questionnaire-response
    let parameter: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "questionnaire-response",
          resource: getQuestionnaireResponseAsResource(),
        },
      ],
    };
    await fhirOperationClient
      .operation({
        name: "extract",
        input: parameter,
        resourceType: "QuestionnaireResponse",
      })
      .then((response) => {
        parseExtractResponse(response);
        setIsfailingOperation(false);
      })
      .catch((error) => {
        setIsExtractLoading(false);
        setIsfailingOperation(true);
        // See errors in the code editor (Output)
        setOutputContent([
          {
            name: "error",
            type: "Error",
            content: error.response?.data
              ? JSON.stringify(error.response?.data, null, "\t")
              : error.toString(),
          },
        ]);
        // A toast when an error occured
        createToast({
          title: i18n.t("text.errorvalidateheader"),
          icon: faTriangleExclamation,
          body: error.response
            ? JSON.stringify(
                error.response.data.issue[0].diagnostics,
                null,
                "\t"
              )
            : i18n.t("text.errorvalidatetext"),
        });
      });
    setIsExtractLoading(false);
  }

  //////////////////////////////
  //          Content         //
  //////////////////////////////

  return (
    <JanusTestablePage
      titleKey="Questionnaire"
      loading={loading}
      needsLogin={true}
      urlTestable={questionnaireResource.url}
      testUrl={process.env.REACT_APP_TEST_URL}
      testServerUrl={process.env.REACT_APP_TEST_SERVER_URL}
      serverId={process.env.REACT_APP_R4_SERVER_ID}
      clientId={process.env.REACT_APP_R4_CLIENT_ID}
    >
      <>
        {/* INFORMATION */}
        <div className={styles.testAccordionSection}>
          <div className="section">
            <Card className={styles.card}>
              <Card.Header>
                <Title level={2} content="Informations" />
              </Card.Header>

              <Card.Body className="flexWrap">
                <div className={styles.form}>
                  <div className={styles.formTextLabel}>
                    <Form.Label className={styles.formLabel}>
                      <strong className={styles.label}>ID :</strong>
                    </Form.Label>
                    <Form.Text>{questionnaireId}</Form.Text>
                  </div>

                  <div className={styles.formTextLabel}>
                    <Form.Label className={styles.formLabel}>
                      <strong className={styles.label}>
                        {i18n.t("label.name")} :
                      </strong>
                    </Form.Label>
                    <Form.Text>{name}</Form.Text>
                  </div>

                  <div className={[styles.badgeContainer, "flexWrap"].join(" ")}>
                    <Form.Label className={styles.statuslabel}>
                      <strong>{i18n.t("label.status")} :</strong>
                    </Form.Label>
                    <Form.Text
                      className={[styles.formText, styles.tagMargin].join(" ")}
                    >
                      <StatusTag
                        status={FhirStatus[status as keyof typeof FhirStatus]}
                        statusMessage={status}
                      />
                    </Form.Text>
                  </div>

                  <div className={styles.formTextLabel}>
                    <Form.Label className={styles.formLabel}>
                      <strong className={styles.label}>
                        {i18n.t("label.generaldescription")} :
                      </strong>
                    </Form.Label>
                    <Form.Text>{description}</Form.Text>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>

          <div className="section">
            {/* BUTTONS TOGGLE - SHOW OR HIDE CARDS*/}
            <div className="d-flex mb-3 justify-content-center">
              <Button
                variant={showQuestionnaire ? "primary" : "outline-primary"}
                className={`${"toggleButton"} ${
                  showQuestionnaire
                    ? styles.noHoverPrimary
                    : styles.noHoverOutlinePrimary
                }`}
                onClick={toggleQuestionnaire}
              >
                <FontAwesomeIcon
                  icon={showQuestionnaire ? faEye : faEyeSlash}
                  className="me-3"
                />
                Questionnaire
              </Button>
              <Button
                variant={
                  showQuestionnaireResponse ? "primary" : "outline-primary"
                }
                className={`${"me-3 ms-3 toggleButton"} ${
                  showQuestionnaireResponse
                    ? styles.noHoverPrimary
                    : styles.noHoverOutlinePrimary
                }`}
                onClick={toggleQuestionnaireResponse}
              >
                <FontAwesomeIcon
                  icon={showQuestionnaireResponse ? faEye : faEyeSlash}
                  className="me-3"
                />
                {i18n.t("text.questionnaireresponse")}
              </Button>
              <Button
                variant={showOutput ? "primary" : "outline-primary"}
                className={`${"toggleButton"} ${
                  showOutput
                    ? styles.noHoverPrimary
                    : styles.noHoverOutlinePrimary
                }`}
                onClick={toggleOutput}
              >
                <FontAwesomeIcon
                  icon={showOutput ? faEye : faEyeSlash}
                  className="me-3"
                />
                Output
              </Button>
            </div>

            <CardGroup className="scrollable">
              {/* Questionnaire IDE - Questionnaire ENGINE */}
              {/* Questionnaire */}
              {showQuestionnaire && (
                <Card className={styles.card}>
                  <Card.Header className="flexWrapSpaceBetween">
                    <Title level={2} content={"Questionnaire"} />
                    <div className={styles.buttonPadding}>
                      <TooltipCustom
                        id="tooltipSaveButton"
                        text={i18n.t("tooltip.save")}
                      >
                        <FontAwesomeIcon
                          icon={faSave}
                          size="2x"
                          className="actionIcon"
                          onClick={onSave}
                        />
                      </TooltipCustom>

                      <TooltipCustom
                        id="tooltipActualizeButton"
                        text={i18n.t("tooltip.disabled")}
                      >
                        <FontAwesomeIcon
                          icon={faGear}
                          size="2x"
                          className="buttonIconDisabled"
                        />
                      </TooltipCustom>

                      <TooltipCustom
                        id="tooltipActualizeButton"
                        text={i18n.t("tooltip.reset")}
                      >
                        <FontAwesomeIcon
                          icon={faRotateRight}
                          size="2x"
                          className="actionIcon"
                          onClick={resetQuestionnaireContent}
                        />
                      </TooltipCustom>

                      <LoadingButton
                        isLoading={isDataLoading}
                        icon={faPlay}
                        onClick={onPopulate}
                        enabledTooltipText={i18n.t("tooltip.play")}
                        operationName="$Populate"
                      />
                    </div>
                  </Card.Header>

                  <Card.Body>
                    <CodeEditor
                      content={questionnaireContent}
                      setContent={setQuestionnaireContent}
                    />
                  </Card.Body>
                </Card>
              )}
              {/* QuestionnaireResponse */}
              {showQuestionnaireResponse && (
                <Card className={styles.card}>
                  <Card.Header className="flexWrapSpaceBetween">
                    <Title
                      level={2}
                      content={i18n.t("text.questionnaireresponse")}
                    />
                    <div className={styles.buttonPadding}>
                      <LoadingButton
                        isLoading={isExtractLoading}
                        enabledTooltipText={i18n.t("tooltip.play")}
                        icon={faPlay}
                        onClick={onExtract}
                        isDisabled={isPopulated}
                        disabledTooltipText={i18n.t(
                          "tooltip.disabledplayquestionnaire"
                        )}
                        operationName="$Extract"
                      />
                    </div>
                  </Card.Header>
                  {
                    <Card.Body>
                      <Form.Select className="select"
                                    value={ responseFormat }
                                    onChange={ e => setResponseFormat(e.target.value) }
                                >
                                    <option value="JSON">
                                        JSON
                                    </option>
                                    <option disabled={!questionnaireResponseContent} value="VIEW">
                                        View
                                    </option>
                                </Form.Select>
                    
                    { responseFormat === "JSON" && 
                      <CodeEditor
                        content={questionnaireResponseContent}
                        setContent={(value: any) =>
                          setQuestionnaireResponseContent(value)
                        }
                      />
                    }
                    { responseFormat === "VIEW" &&
                      <QuestionnaireDisplay
                        language={ i18n.t }
                        questionnaire={ getQuestionnaireAsResource() }
                        questionnaireResponse={ getQuestionnaireResponseAsResource() }
                        //See if we want to configure that
                        valueSetLoader={ new ValueSetLoader(fhirClient) }
                        submitButtonLabel="Validate answers"
                        onSubmit={() => { }}
                        onError={() => { }}
                      />
                    }
                    </Card.Body>
                  }
                </Card>
              )}
              {/* Extraction - Output */}
              {showOutput && (
                <Output
                  content={outputContent}
                  setContent={setOutputContent}
                  isFailingOperation={isFailingOperation}
                />
              )}
            </CardGroup>
          </div>
        </div>
      </>
    </JanusTestablePage>
  );
};

export default QuestionnaireDetail;
