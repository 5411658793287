// React
import { FunctionComponent } from "react";
// Components
import JanusPage from "../../components/JanusPage/JanusPage";

const EditStructureMap: FunctionComponent = () => {
    
  //////////////////////////////
  //          Content         //
  //////////////////////////////

  return (
    <JanusPage
      titleKey="title.editstructuremap"
      loading={false}
      fitFooter={true}
      needsLogin={true}
    >
      <>Add content here - EditStructureMap</>
    </JanusPage>
  );
};

export default EditStructureMap;
