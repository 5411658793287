// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CodeEditor_main__Luqa0 {
  width: 80%;
  margin: auto;
}

.CodeEditor_card__uvYKo {
  margin-bottom: 3rem;
  width: 100%;
}

.CodeEditor_buttonPadding__TL61H {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.CodeEditor_noResizeTextArea__-xxOw {
  resize: none;
  height: 75vh;
  width: 100%;
}

.CodeEditor_scrollable__KzXya {
  max-height: 85vh;
  overflow: scroll;
}

.CodeEditor_headerOutput__egdoi {
  margin: 1rem;
}

.CodeEditor_headerOutputPadding__WHEOc {
  padding-top: 0.5rem !important;
}

.CodeEditor_cardOutputPadding__WH5Uz {
  padding: 0 !important;
}

.CodeEditor_link__jMnWG:hover {
  color: var(--info-color);
  text-decoration: underline;
  cursor: pointer;
}

.CodeEditor_alertTitle__q3IzN {
  margin-left: 1rem;
}

.CodeEditor_alertContainer__7Twyd {
  display: flex;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/components/CodeEditor/CodeEditor.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,wBAAwB;EACxB,0BAA0B;EAC1B,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC","sourcesContent":[".main {\r\n  width: 80%;\r\n  margin: auto;\r\n}\r\n\r\n.card {\r\n  margin-bottom: 3rem;\r\n  width: 100%;\r\n}\r\n\r\n.buttonPadding {\r\n  display: flex;\r\n  align-items: center;\r\n  gap: 1rem;\r\n}\r\n\r\n.noResizeTextArea {\r\n  resize: none;\r\n  height: 75vh;\r\n  width: 100%;\r\n}\r\n\r\n.scrollable {\r\n  max-height: 85vh;\r\n  overflow: scroll;\r\n}\r\n\r\n.headerOutput {\r\n  margin: 1rem;\r\n}\r\n\r\n.headerOutputPadding {\r\n  padding-top: 0.5rem !important;\r\n}\r\n\r\n.cardOutputPadding {\r\n  padding: 0 !important;\r\n}\r\n\r\n.link:hover {\r\n  color: var(--info-color);\r\n  text-decoration: underline;\r\n  cursor: pointer;\r\n}\r\n\r\n.alertTitle {\r\n  margin-left: 1rem;\r\n}\r\n\r\n.alertContainer {\r\n  display: flex;\r\n  justify-content: space-between;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `CodeEditor_main__Luqa0`,
	"card": `CodeEditor_card__uvYKo`,
	"buttonPadding": `CodeEditor_buttonPadding__TL61H`,
	"noResizeTextArea": `CodeEditor_noResizeTextArea__-xxOw`,
	"scrollable": `CodeEditor_scrollable__KzXya`,
	"headerOutput": `CodeEditor_headerOutput__egdoi`,
	"headerOutputPadding": `CodeEditor_headerOutputPadding__WHEOc`,
	"cardOutputPadding": `CodeEditor_cardOutputPadding__WH5Uz`,
	"link": `CodeEditor_link__jMnWG`,
	"alertTitle": `CodeEditor_alertTitle__q3IzN`,
	"alertContainer": `CodeEditor_alertContainer__7Twyd`
};
export default ___CSS_LOADER_EXPORT___;
