// React
import { FunctionComponent } from "react";
// Components
import JanusPage from "../../components/JanusPage/JanusPage";

const EditQuestionnaire: FunctionComponent = () => {

  //////////////////////////////
  //          Content         //
  //////////////////////////////
  
  return (
    <JanusPage
      titleKey="title.editquestionnaire"
      loading={false}
      fitFooter={true}
      needsLogin={true}
    >
      <>Add content here - EditQuestionnaire</>
    </JanusPage>
  );
};

export default EditQuestionnaire;
