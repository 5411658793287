// React
import { FunctionComponent, useCallback, useEffect, useState } from "react";
// Components
import JanusTestablePage from "../../components/JanusTestablePage/JanusTestablePage";
import CodeEditor from "../../components/CodeEditor/CodeEditor";
import Output from "../../components/CodeEditor/Output";
import LoadingButton from "../../components/LoadingButton/LoadingButton";
import { ToastQueueProvider } from "../../components/ToastQueueProvider/ToastQueueProvider";
import TooltipCustom from "../../components/TooltipWrapper/TooltipWrapper";
// Fhir Front Library
import { FhirStatus, StatusTag, Title } from "@fyrstain/fhir-front-library";
// FHIR
import Client from "fhir-kit-client";
// Resource StructureMap
import { StructureMap, Parameters } from "fhir/r5";
// Translation
import i18n from "i18next";
// React Bootstrap
import { Card, CardGroup, Form, Button } from "react-bootstrap";
// Navigation
import { useNavigate, useParams } from "react-router-dom";
// Styles
import styles from "./StructureMapDetails.module.css";
// Buffer
import { Buffer } from "buffer";
// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faGear,
  faPlay,
  faArrowsRotate,
  faEye,
  faEyeSlash,
  faCircleCheck,
  faTriangleExclamation,
  faRotateRight,
} from "@fortawesome/free-solid-svg-icons";

////////////////////////////////
//         Interfaces         //
////////////////////////////////

interface inputs {
  name: string;
  type: string;
  mode: string;
  content?: string;
  profile?: string;
}

interface structure {
  alias: string;
  mode: string;
  url: string;
}

const StructureMapDetail: FunctionComponent = () => {
    
  /////////////////////////////////////
  //      Constants / ValueSet       //
  /////////////////////////////////////

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // StructureMap informations
  const { structureMapId } = useParams();
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");

  // Contents &  Resources
  const [structureMapContent, setStructureMapContent] = useState("");
  const [structuremapResource, setStructuremapResource] = useState(
    {} as StructureMap
  );
  const [initialStructureMapContent, setInitialStructureMapContent] =
    useState("");
  const [sourceContent, setSourceContent] = useState(new Array<inputs>());
  const [targetContent, setTargetContent] = useState(new Array<inputs>());
  const [outputContent, setOutputContent] = useState(new Array<inputs>());

  // To enable or disabled the "play" button when the user has modified the Structure Map content
  const [isModified, setIsModified] = useState(false);

  // Show the spinner when it's "true" and hide when "false"
  const [isDataLoading, setIsDataLoading] = useState(false);

  // To disable the "check" button to the Output field when the user has an error
  const [isFailingOperation, setIsfailingOperation] = useState(false);

  // To show or hide the content/card with the buttonToggle
  const [showSources, setShowSources] = useState(true);
  const [showTargets, setShowTargets] = useState(true);
  const [showOutputs, setShowOutputs] = useState(true);

  // Creating a Toast using the Component Toast
  const { createToast } = ToastQueueProvider.useToastQueue();

  /////////////////////////////////////
  //             Client              //
  /////////////////////////////////////

  const fhirClient = new Client({
    baseUrl: process.env.REACT_APP_FHIR_URL ?? "fhir",
  });

  const fhirOperationClient = new Client({
    baseUrl: process.env.REACT_APP_MAPPING_URL ?? "fhir",
  });

  //////////////////////////////
  //           Error          //
  //////////////////////////////

  const onError = useCallback(() => {
    navigate("/Error");
  }, [navigate]);

  ////////////////////////////////
  //           Actions          //
  ////////////////////////////////

  useEffect(() => {
    load();
  }, []);

  /**
   * To check the state of setIsModified (true or false) based on a difference between the initial content and the changes
   */
  useEffect(() => {
    setIsModified(structureMapContent !== initialStructureMapContent);
  }, [structureMapContent, initialStructureMapContent]);

  /**
   * To load the StructureMap and switch the state of setLoading
   */
  async function load() {
    setLoading(true);
    await loadStructureMap();
    setLoading(false);
  }

  /**
   * Load StructureMap from the back to populate the fields.
   *
   * @returns the promise of a StructureMap.
   */
  async function loadStructureMap() {
    try {
      const response = await fhirClient.read({
        resourceType: "StructureMap",
        id: structureMapId ?? "",
      });
      if (response.resourceType !== "StructureMap") {
        throw Error(response.statusText);
      }

      const structureMap: StructureMap = response as StructureMap;
      let stringifiedStructureMap = JSON.stringify(structureMap, null, "\t");

      setName(structureMap.title ? structureMap.title : "N/A");
      setStatus(structureMap.status ? structureMap.status : "N/A");
      setDescription(
        structureMap.description ? structureMap.description : "N/A"
      );
      setStructureMapContent(stringifiedStructureMap);
      setStructuremapResource(structureMap);
      setInitialStructureMapContent(stringifiedStructureMap);
      setIsModified(false);
      actualizeWithContent(structureMap);
    } catch (error) {
      onError();
    }
  }

  //Functions to show or hide the cards with a ToggleButton
  function toggleSources() {
    setShowSources(!showSources);
  }
  function toggleTargets() {
    setShowTargets(!showTargets);
  }
  function toggleOutputs() {
    setShowOutputs(!showOutputs);
  }

  // Functions to retrieves main group, structures and inputs of the resource StructureMap
  function getMainGroup(structureMap: StructureMap): any {
    return structureMap.group.filter((group) => group.name === "main")[0];
  }
  function getStructures(structureMap: StructureMap): Array<structure> {
    return structureMap.structure as Array<structure>;
  }
  function getInputs(mainGroup: any, mode: string): Array<inputs> {
    return mainGroup.input.filter((entry: inputs) => entry.mode === mode);
  }

  /**
   * Update the content and profile information based on the mode
   * @param mainGroup The main group object from the StructureMap.
   * @param mode The mode can be "source" or "target"
   * @param contents An array of inputs including the name and content
   * @param structures An array of structure for the profile
   * @returns an array of inputs, updated
   */
  function processInputs(
    mainGroup: any,
    mode: string,
    contents: Array<inputs>,
    structures: Array<structure>
  ) {
    let inputs = getInputs(mainGroup, mode);
    inputs.map((input) => {
      // Input Content
      const currentContent = contents.filter(
        (content) => content.name === input.name
      );
      input.content = currentContent[0] ? currentContent[0].content : undefined;
      // Profile for Input Validation
      const profile = structures.filter(
        (structure) =>
          structure.alias === input.name && structure.mode === input.mode
    );      
    input.profile = profile[0] ? profile[0].url : undefined;      
    });
    return inputs;
  }

  /**
   * Update the source, target, and output contents based on the StructureMap.
   * @param content of the StructureMap to update
   */
  function actualizeWithContent(content: StructureMap) {
    let mainGroup = getMainGroup(content);
    let structures = getStructures(content);

    setSourceContent(
      processInputs(mainGroup, "source", sourceContent, structures)
    );

    setTargetContent(
      processInputs(mainGroup, "target", targetContent, structures)
    );

    setOutputContent(
      processInputs(mainGroup, "target", outputContent, structures)
    );
  }

  /**
   * Function to parse the structureMap content and actualize the current structureMapContent
   */
  function actualize() {
    try {
      actualizeWithContent(JSON.parse(structureMapContent) as StructureMap);
      setIsModified(false);
    } catch (error) {
      onError();
    }
  }

  /**
   * Function to decode the resource if it's a type "Binary" and update the content of outputContent
   * by transforming it into a JSON type
   * @param response is an object containing parameters to be parsed.
   */
  function parseResponse(response: Parameters) {
    var newOutputs = structuredClone(outputContent);
    response.parameter?.map((param, index) => {
      if (param.resource?.resourceType === "Binary" && param.resource.data) {
        let decodedBinary = Buffer.from(
          param.resource.data,
          "base64"
        ).toString();
        switch (param.resource.contentType) {
          case "json":
            newOutputs[index].content = JSON.stringify(
              JSON.parse(decodedBinary),
              null,
              "\t"
            );
            break;
          default:
            newOutputs[index].content = decodedBinary;
            break;
        }
      }
    });
    setOutputContent(newOutputs);
  }

  /**
   * Save the content of the StructureMap
   */
  async function onSave() {
    actualize();
    try {
      await fhirClient.update({
        resourceType: "StructureMap",
        id: structureMapId ?? "",
        body: JSON.parse(structureMapContent),
      });
    } catch (error) {
      onError();
    }
  }

  /**
   * Reset the StructureMap content, returning the initial content
   */
  const resetStructureMapContent = () => {
    setStructureMapContent(initialStructureMapContent);
  };

  /**
   * Allows validation even if it's empty or with a space
   * @param value is the content to be checked 
   * @returns true if the content is not empty and without spaces
   */
  const isNotBlank = (value: any) => {
    return value.trim().length > 0;
  };

  /**
   * Function using the transform operation to update the state of outputContent
   */
  async function onTransform() {
    setIsDataLoading(true);
    // Use the parameters structureMap and input
    let parameter: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "structureMap",
          resource: JSON.parse(structureMapContent),
        },
        {
          name: "input",
          part: [],
        },
      ],
    };
    // Retrieve source content to use it for the operation
    sourceContent.forEach((entry) => {
      if (
        parameter.parameter &&
        parameter.parameter[1].part &&
        entry.content &&
        isNotBlank(entry.content)
      ) {
        parameter.parameter[1].part.push({
          name: entry.name,
          resource: {
            resourceType: "Binary",
            contentType: getContentType(entry.type),
            data: Buffer.from(entry.content).toString("base64"),
          },
        });
      }
    });
    // Retrieve target content to use it for the operation
    targetContent.forEach((entry) => {
      if (
        parameter.parameter &&
        parameter.parameter[1].part &&
        entry.content &&
        isNotBlank(entry.content)
      ) {
        parameter.parameter[1].part.push({
          name: entry.name,
          resource: {
            resourceType: "Binary",
            contentType: getContentType(entry.type),
            data: Buffer.from(entry.content).toString("base64"),
          },
        });
      }
    });
    await fhirOperationClient
      .operation({
        name: "transform",
        input: parameter,
      })
      .then((response) => {
        parseResponse(response);
        setIsfailingOperation(false);
      })
      .catch((error) => {
        setIsDataLoading(false);
        setIsfailingOperation(true);
        // See errors in the code editor (Output)
        setOutputContent([
          {
            name: "error",
            type: "Error",
            content: error.response?.data
              ? JSON.stringify(error.response?.data, null, "\t")
              : error.toString(),
            mode: "",
          },
        ]);
        // A toast when an error occured
        createToast({
          title: i18n.t("text.errorvalidateheader"),
          icon: faTriangleExclamation,
          body: error.response
            ? JSON.stringify(
                error.response.data.issue[0].diagnostics,
                null,
                "\t"
              )
            : i18n.t("text.errorvalidatetext"),
        });
      });
    setIsDataLoading(false);
  }

  /**
   * Returns the contentType depending on the input type.
   * 
   * @param type the input type
   * @returns the contentType
   */
  function getContentType(type: string): string {
    switch (type) {
      case 'CSV':
        return "text/csv";
      case 'XML':
        return "application/xml";
      case 'HL7v2':
        return "text/x-hl7-ft";
      default:
        return "application/json";
    }
  }

  //////////////////////////////
  //          Content         //
  //////////////////////////////

  return (
    <JanusTestablePage
      titleKey="Structure Map"
      loading={loading}
      needsLogin={true}
      urlTestable={structuremapResource.url}
      testUrl={process.env.REACT_APP_TEST_URL}
      testServerUrl={process.env.REACT_APP_TEST_SERVER_URL}
      serverId={process.env.REACT_APP_R4_SERVER_ID}
      clientId={process.env.REACT_APP_R4_CLIENT_ID}
    >
      <>
        {/* INFORMATION */}
        <div className={styles.testAccordionSection}>
          <div className="section">
            <Card className={styles.card}>
              <Card.Header>
                <Title level={2} content="Informations" />
              </Card.Header>

              <Card.Body className="flexWrap">
                <div className={styles.form}>
                  <div className={styles.formTextLabel}>
                    <Form.Label className={styles.formLabel}>
                      <strong className={styles.label}>ID :</strong>
                    </Form.Label>
                    <Form.Text>{structureMapId}</Form.Text>
                  </div>

                  <div className={styles.formTextLabel}>
                    <Form.Label className={styles.formLabel}>
                      <strong className={styles.label}>
                        {i18n.t("label.name")} :
                      </strong>
                    </Form.Label>
                    <Form.Text>{name}</Form.Text>
                  </div>

                  <div className={[styles.badgeContainer, "flexWrap"].join(" ")}>
                    <Form.Label className={styles.statuslabel}>
                      <strong>{i18n.t("label.status")} :</strong>
                    </Form.Label>
                    <Form.Text
                      className={[styles.formText, styles.tagMargin].join(" ")}
                    >
                      <StatusTag
                        status={FhirStatus[status as keyof typeof FhirStatus]}
                        statusMessage={status}
                      />
                    </Form.Text>
                  </div>

                  <div className={styles.formTextLabel}>
                    <Form.Label className={styles.formLabel}>
                      <strong className={styles.label}>
                        {i18n.t("label.generaldescription")} :
                      </strong>
                    </Form.Label>
                    <Form.Text>{description}</Form.Text>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>

          {/* StructureMap IDE - StructureMap ENGINE */}
          <div className="section">
            <div className="displayFlexCenter">
              {/* INPUT */}
              <Card className={styles.card}>
                <Card.Header className="flexWrapSpaceBetween">
                  <Title level={2} content={"Structure Map"} />
                  <div className={styles.buttonPadding}>
                    <TooltipCustom
                      id="tooltipSaveButton"
                      text={i18n.t("tooltip.save")}
                    >
                      <FontAwesomeIcon
                        icon={faSave}
                        size="2x"
                        onClick={onSave}
                        className="actionIcon"
                      />
                    </TooltipCustom>

                    <TooltipCustom
                      id="tooltipActualizeButton"
                      text={i18n.t("tooltip.actualize")}
                    >
                      <FontAwesomeIcon
                        icon={faArrowsRotate}
                        size="2x"
                        onClick={actualize}
                        className="actionIcon"
                      />
                    </TooltipCustom>

                    <TooltipCustom
                      id="tooltipConfigButton"
                      text={i18n.t("tooltip.disabled")}
                    >
                      <FontAwesomeIcon
                        icon={faGear}
                        size="2x"
                        className="buttonIconDisabled"
                      />
                    </TooltipCustom>
                    <TooltipCustom
                      id="tooltipActualizeButton"
                      text={i18n.t("tooltip.reset")}
                    >
                      <FontAwesomeIcon
                        icon={faRotateRight}
                        size="2x"
                        className="actionIcon"
                        onClick={resetStructureMapContent}
                      />
                    </TooltipCustom>
                    <LoadingButton
                      icon={faPlay}
                      isLoading={isDataLoading}
                      onClick={onTransform}
                      isDisabled={isModified}
                      disabledTooltipText={i18n.t(
                        "tooltip.disabledplaystructuremap"
                      )}
                      enabledTooltipText={i18n.t("tooltip.play")}
                      operationName="$Transform"
                    />
                  </div>
                </Card.Header>

                <Card.Body>
                  <CodeEditor
                    content={structureMapContent}
                    setContent={setStructureMapContent}
                  />
                </Card.Body>
              </Card>
            </div>
          </div>

          <div className="section">
            {/* BUTTONS TOGGLE - SHOW OR HIDE CARDS*/}
            <div className="d-flex mb-3 justify-content-center">
              <Button
                variant={showSources ? "primary" : "outline-primary"}
                className={`${"toggleButton"} ${
                  showSources
                    ? styles.noHoverPrimary
                    : styles.noHoverOutlinePrimary
                }`}
                onClick={toggleSources}
              >
                <FontAwesomeIcon
                  icon={showSources ? faEye : faEyeSlash}
                  className="me-3"
                />
                Sources
              </Button>
              <Button
                variant={showTargets ? "primary" : "outline-primary"}
                className={`${"me-3 ms-3 toggleButton"} ${
                  showTargets
                    ? styles.noHoverPrimary
                    : styles.noHoverOutlinePrimary
                }`}
                onClick={toggleTargets}
              >
                <FontAwesomeIcon
                  icon={showTargets ? faEye : faEyeSlash}
                  className="me-3"
                />
                Targets
              </Button>
              <Button
                variant={showOutputs ? "primary" : "outline-primary"}
                className={`${"toggleButton"} ${
                  showOutputs
                    ? styles.noHoverPrimary
                    : styles.noHoverOutlinePrimary
                }`}
                onClick={toggleOutputs}
              >
                <FontAwesomeIcon
                  icon={showOutputs ? faEye : faEyeSlash}
                  className="me-3"
                />
                Outputs
              </Button>
            </div>

            <CardGroup className="scrollable">
              {/* INPUT */}
              {showSources && (
                <Card className={styles.card}>
                  <Card.Header className="flexWrapSpaceBetween">
                    <Title level={2} content={"Sources"} />
                  </Card.Header>
                  <div className={styles.scrollable}>
                    {sourceContent.map((entry, index) => (
                      <Card.Body key={index}>
                        <Card className={styles.card}>
                          <Card.Header className="flexWrapSpaceBetween headerEditor">
                            <div className={styles.buttonPadding}>
                              {entry.name.toUpperCase()}
                              <TooltipCustom
                                id="tooltipCheckButton"
                                text={i18n.t("tooltip.disabled")}
                              >
                                <FontAwesomeIcon
                                  icon={faCircleCheck}
                                  size="2x"
                                  className="buttonIconDisabled"
                                />
                              </TooltipCustom>
                            </div>
                          </Card.Header>
                          <Card.Body>
                            <CodeEditor
                              content={entry.content ?? "//" + entry.name}
                              setContent={(value: any) => (entry.content = value)}
                            />
                          </Card.Body>
                        </Card>
                      </Card.Body>
                    ))}
                  </div>
                </Card>
              )}
              {/* TARGET */}
              {showTargets && (
                <Card className={styles.card}>
                  <Card.Header className="flexWrapSpaceBetween">
                    <Title level={2} content={"Targets"} />
                  </Card.Header>
                  <div className={styles.scrollable}>
                    {targetContent.map((entry, index) => (
                      <Card.Body key={index}>
                        <Card className={styles.card}>
                          <Card.Header className="flexWrapSpaceBetween headerEditor">
                            <div className={styles.buttonPadding}>
                              {entry.name.toUpperCase()}
                              <TooltipCustom
                                id="tooltipCheckButton"
                                text={i18n.t("tooltip.disabled")}
                              >
                                <FontAwesomeIcon
                                  icon={faCircleCheck}
                                  size="2x"
                                  className="buttonIconDisabled"
                                />
                              </TooltipCustom>
                            </div>
                          </Card.Header>
                          <Card.Body>
                            <CodeEditor
                              content={entry.content ?? "//" + entry.name}
                              setContent={(value: any) => (entry.content = value)}
                            />
                          </Card.Body>
                        </Card>
                      </Card.Body>
                    ))}
                  </div>
                </Card>
              )}
              {/* OUTPUT */}
              {showOutputs && (
                <Output
                  content={outputContent}
                  setContent={setOutputContent}
                  isFailingOperation={isFailingOperation}
                />
              )}
            </CardGroup>
          </div>
        </div>
      </>
    </JanusTestablePage>
  );
};

export default StructureMapDetail;
