// React
import { FunctionComponent } from "react";
// StructureMap
import { Title } from "@fyrstain/fhir-front-library";
// Components
import JanusPage from "../../components/JanusPage/JanusPage";
// Styles
import styles from "./Home.module.css";

const Home: FunctionComponent = () => {
    
  //////////////////////////////
  //          Content         //
  //////////////////////////////

  return (
    <JanusPage loading={false} fitFooter={true} needsLogin={false}>
      <>
        <div className={styles.mainHomeContainer}>
          <img
            className={styles.homeimageIcon}
            alt="Home_image"
            src="/assets/home.png"
          />
          <Title
            level={1}
            prefix={"Janus"}
            content="Structure Maps & Questionnaires"
          />
        </div>
      </>
    </JanusPage>
  );
};

export default Home;
