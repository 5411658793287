// React
import React, { useState } from "react";
// Components
import { ToastQueueProvider } from "../ToastQueueProvider/ToastQueueProvider";
import LoadingButton from "../LoadingButton/LoadingButton";
import TooltipCustom from "../TooltipWrapper/TooltipWrapper";
// React Bootstrap
import { Card, Form } from "react-bootstrap";
// Styles
import styles from "./CodeEditor.module.css";
// Fhir Front Library
import {
  FhirStatus,
  StatusAlert,
  StatusIcon,
  Title,
} from "@fyrstain/fhir-front-library";
// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRotateRight,
  faCircleCheck,
  faXmark,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
// FHIR
import { Parameters, OperationOutcome, Bundle } from "fhir/r5";
import Client from "fhir-kit-client";
// Translation
import i18n from "i18next";

////////////////////////////////
//           Props            //
////////////////////////////////

interface OutputProps {
  content: Array<inputs>;
  setContent: any;
  isFailingOperation?: boolean;
}

////////////////////////////////
//         Interfaces         //
////////////////////////////////

interface inputs {
  name: string;
  type: string;
  content?: string;
  profile?: string;
  validationReportID?: string;
  validationReportStatus?: string;
}

interface ShowAlertType {
  [key: number]: boolean;
}

const Output: React.FC<OutputProps> = ({ content, setContent, isFailingOperation }) => {

  /////////////////////////////////////
  //      Constants / ValueSet       //
  /////////////////////////////////////

  const [showAlert, setShowAlert] = useState<ShowAlertType>({});

  // Show the spinner when "true" and hide when "false"
  const [isDataLoading, setIsDataLoading] = useState(false);

  // Creating a Toast using the Component Toast
  const { createToast } = ToastQueueProvider.useToastQueue();

  /////////////////////////////////////
  //             Client              //
  /////////////////////////////////////

  const fhirOperationClient = new Client({
    baseUrl: process.env.REACT_APP_VALIDATE_URL ?? "fhir",
  });

  ////////////////////////////////
  //           Actions          //
  ////////////////////////////////

  /**
   * Open the only one alert of the card and update the state
   * @param index ID/position of an alert in the list
   */
  const handleOpenAlert = (index: number) => {
    setShowAlert({
      ...showAlert,
      [index]: true,
    });
  };

  /**
   * Close the alert of the card
   * @param index ID/position of an alert in the list
   */
  const handleCloseAlert = (index: number) => {
    setShowAlert({
      ...showAlert,
      [index]: false,
    });
  };

  /**
   * Capitalize the first letter
   *
   * @param string the string to capitalize
   */
  function capitalizeFirst(string: string): string {
    const splitted = string.split("");
    splitted[0] = splitted[0].toUpperCase();
    return splitted.join("");
  }

  /**
   * Use the operation validate
   * @param input input to validate with data or a profile
   * @param index index to open only one Alert
   */
  async function validate(input: inputs, index: any) {
    handleOpenAlert(index);
    setIsDataLoading(true);
    let parameter: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "validatorId",
          valueString: "09c3f5d9-98a3-4147-b40c-1e58ecc54fab",
        },
        {
          name: "resource",
          valueString: input.content,
        },
      ],
    };
    if (input.profile) {
      parameter.parameter?.push({
        name: "profile",
        valueCanonical: input.profile,
      });
    }
    await fhirOperationClient
      .operation({
        name: "validate",
        input: parameter,
      })
      .then((response) => {
        parseValidateResponse(response, input);
      })
      .catch((error) => {
        setIsDataLoading(false);
        // A toast when an error occured
        createToast({
          title: i18n.t("text.errorvalidateheader"),
          icon: faTriangleExclamation,
          body: error.response
            ? JSON.stringify(
                error.response.data.issue[0].diagnostics,
                null,
                "\t"
              )
            : i18n.t("text.errorvalidatetext"),
        });
      });
    let newcontent = [...content];
    setContent(newcontent);
    setIsDataLoading(false);
  }

  /**
   * Parse the validation response and update the input with the validation report details
   * @param response the response received from the FHIR validate operation
   * @param input the input object to update with the validation report details
   */
  function parseValidateResponse(response: Bundle, input: inputs) {
    let validationStatus: any = "";
    input.validationReportID = response.id;
    let responseFiltered = response.entry?.filter(
      (l) => l.resource?.resourceType === "OperationOutcome"
    )[0].resource as OperationOutcome;
    if (
      responseFiltered &&
      responseFiltered.issue[0] &&
      responseFiltered.issue[0].details &&
      responseFiltered.issue[0].details.coding
    ) {
      validationStatus = responseFiltered.issue[0].details.coding[0].code;
      input.validationReportStatus = validationStatus ? validationStatus : "";
    }
  }

  //////////////////////////////
  //          Content         //
  //////////////////////////////

  return (
    <>
      <Card className={styles.card}>
        <Card.Header className="flexWrapSpaceBetween">
          <Title level={2} content={"Output"} />
          <div className={styles.buttonPadding}>
            <TooltipCustom
              id="tooltipResetButton"
              text={i18n.t("tooltip.disabled")}
            >
              <FontAwesomeIcon
                icon={faRotateRight}
                size="2x"
                className="buttonIconDisabled"
              />
            </TooltipCustom>
          </div>
        </Card.Header>

        <div className={[styles.scrollable, styles.headerOutput].join(" ")}>
          <Form.Group className="col">
            {content.length == 0 && (
              <Card.Body className={styles.cardOutputPadding}>
                <Form.Control
                  as="textarea"
                  rows={22}
                  name="output"
                  readOnly={true}
                  value={i18n.t("text.outputtext")}
                  onChange={(value: any) => setContent(value)}
                  className={[styles.noResizeTextArea, "form-control"].join(" ")}
                />
              </Card.Body>
            )}
            {content.map((entry, index) => (
              <>
                <Card className={styles.card}>
                  <Card.Header className="headerEditor">
                    <div className={styles.buttonPadding}>
                      {entry.name?.toUpperCase()}
                      {!isFailingOperation && (
                        <LoadingButton
                          isLoading={isDataLoading}
                          onClick={() => validate(entry, index)}
                          enabledTooltipText={i18n.t("tooltip.check")}
                          icon={faCircleCheck}
                        />
                      )}
                    </div>
                  </Card.Header>
                  <Card.Body className={styles.headerOutputPadding}>
                    {showAlert[index] && (
                      <StatusAlert
                        status={
                          FhirStatus[
                            (entry.validationReportStatus ||
                              "unknown") as keyof typeof FhirStatus
                          ]
                        }
                      >
                        <div className={styles.alertContainer}>
                          <div>
                            <StatusIcon
                              status={
                                FhirStatus[
                                  (entry.validationReportStatus ||
                                    "unknown") as keyof typeof FhirStatus
                                ]
                              }
                            />
                            <strong className={styles.alertTitle}>
                              {`${capitalizeFirst(
                                entry.validationReportStatus || "Unknown error"
                              )} validation`}
                            </strong>
                            <div>
                              <a
                                href={`https://demo.fyrstain.com/ValidationReport/${entry.validationReportID}`}
                                target="_blank"
                                className={styles.link}
                              >
                                {entry.validationReportID
                                  ? i18n.t("text.validationreport")
                                  : ""}
                              </a>
                            </div>
                          </div>
                          <div className={styles.xmarkEnd}>
                            <FontAwesomeIcon
                              icon={faXmark}
                              onClick={() => handleCloseAlert(index)}
                              className="actionIcon"
                            />
                          </div>
                        </div>
                      </StatusAlert>
                    )}
                    <Form.Control
                      as="textarea"
                      rows={22}
                      name={"output-" + index}
                      readOnly={true}
                      value={entry.content ? entry.content : i18n.t("text.outputtext")}
                      onChange={(value: any) => setContent(value)}
                      className={[styles.noResizeTextArea, "form-control"].join(" ")}
                    />
                  </Card.Body>
                </Card>
              </>
            ))}
          </Form.Group>
        </div>
      </Card>
    </>
  );
};

export default Output;
